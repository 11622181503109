import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Spinner, Badge } from 'reactstrap';
import { removeSession } from 'store/actions/floorPlanActions';
import '../assets/scss/ComponentsCss/session.scss';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { payWaiter } from 'store/actions/floorPlanActions';

export default function FloorPlanCard({ setAlertMsg, setWarn, data, setOpenAddSessionModal,setSelectedOrder }) {
  const dispatch = useDispatch();
  // const [open, setOpen] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paymentLoader, setpaymentLoader] = useState(true);
  const { removeSessionLoader, paywaiterLoader } = useSelector((state) => state.floor_plans);
  let orderCreatedate = new Date(data.createdAt || '24/11/2022');
  // const handleChange = () => {
  //   setOpen(!open);
  // };
  const { blId } = useSelector((state) => state.blId);
  var total = 0;
  const deleteSesssion = () => {
    if (data.status !== 'partially_paid') {
      setLoader(true);
      dispatch(removeSession(data.sessionId, blId));
    }
  };
  const notifyKitchen = (finalBill) => {
    let msgDetails = `${new Date()} | Pay to Waiter | Table Name: ${
      data?.tableNumber
    } | Order Total: €${finalBill.toFixed(2)}`; //Tip Amount: €${(total - subAmount).toFixed(2)
    // const msg = {
    //   problemID: uuidv4(),
    //   tableNo: data?.tableNumber,
    //   sessionID: data?.sessionId,
    //   message: msgDetails.trim(),
    //   payWaiter: true
    // };
    // socket.emit("Problem_", msg);
    msgDetails = msgDetails + `| Table No: ${data?.tableNumber}`;
    return msgDetails;
  };
  const handleWaiterPayment = () => {
    if (data?.payment_status !== 'paid' || data?.payment_type !== 'pay_all') {
      setpaymentLoader(true);
      let finalBill = data?.salesEntries?.reduce(function (acc, obj) {
        return acc + Number(obj.itemPrice);
      }, 0);
      const waiterPayload = {
        businessLocationId: data?.businessLocationId,
        thirdPartyReference: uuidv4(),
        orderType: 'served',
        sessionID: data?.sessionId,
        tableNumber: data?.tableNumber,
        orderNote: notifyKitchen(finalBill),
        totalAmount: finalBill
      };
      dispatch(payWaiter(waiterPayload));
    } else {
      setAlertMsg('Oops..! This Order has been paid.');
      setWarn(true);
    }
  };

  useEffect(() => {
    if (!removeSessionLoader) {
      setLoader(removeSessionLoader);
    }
  }, [removeSessionLoader]);
  useEffect(() => {
    if (!paywaiterLoader) {
      setpaymentLoader(paywaiterLoader);
    }
  }, [paywaiterLoader]);
  return (
    <Col xs="12" sm="12" md="4" lg="3" className="p-2">
      <div className="card">
        <div className="floor-card-header ">
          <div className="d-flex justify-content-between px-2 py-3">
            <div className="time">
              <b>{orderCreatedate?.toLocaleTimeString()}</b>
            </div>
            <div className="tableNo">
              TABLE: {data?.tableNumber}
              <b></b>
            </div>
            <div className="date">{orderCreatedate.toLocaleDateString()}</div>
          </div>
        </div>
        <div className="order-details px-3 py-1">
          <div>
            NAME: <b>{data?.pagerNumber?data.pagerNumber:data?.sessionName}</b>{' '}
          </div>
          <div className="my-2 d-flex justify-content-between">
            <b>STATUS : {data?.status}</b>
            <b>USERS : -</b>
          </div>
          <div className="my-2">
            <b>Payment Method : {data?.payment_type}</b>
          </div>
          <div className="d-flex justify-content-between my-2" >
            <b>NFC CARDS  </b>
            <img src="/img/plus-icon.png" height={20} onClick={()=>{setOpenAddSessionModal(true);setSelectedOrder(data)}}/>
          </div>
          <div>
            {data?.cards?.map((name, index) => (
              <Badge className="m-2 p-3" color={'info'} pill key={index}>
                {name}
              </Badge>
            ))}
          </div>
          <div className="">ORDERS</div>
        </div>
        <div className="body floor-card-body p-3">
          <div className="bill-items">
            {data?.salesEntries.map((se, index) => {
              total = total + se?.itemPrice;
              return (
                <div key={index}>
                  <div className="d-flex justify-content-between">
                    <span>
                      <b>{se?.quantity}</b>
                    </span>
                    <span>{se?.itemName}</span>
                    <span>
                      <b>{se?.itemPrice} €</b>
                    </span>
                  </div>
                  {se?.subLineItems.map((sl, index) => {
                    total = total + sl?.itemPrice;
                    return (
                      <div className="d-flex justify-content-between" key={index}>
                        <span>{sl?.quantity}</span>
                        <span className="dish-extra">{sl?.itemName}</span>
                        <span className="dish-extra">{sl?.itemPrice} €</span>
                      </div>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="line-bottom w-100 mt-3"></div>
          <div className="p-2 d-flex justify-content-end total">
            <span>
              Tot <b className="ml-3">{total.toFixed(2)} €</b>
            </span>
          </div>
        </div>
        <div className="p-2 d-flex justify-content-center">
          <div>
            <button
              style={loader || paymentLoader ? { opacity: '0.5' } : {}}
              disabled={loader || paymentLoader}
              onClick={deleteSesssion}
              className=" d-block mt-2 butn del-btn">
              {loader ? (
                <Spinner className="d-flex mx-auto" size="sm" color="white" />
              ) : (
                'DELETE SESSION'
              )}
            </button>
            <button
              style={loader || paymentLoader ? { opacity: '0.5' } : {}}
              disabled={loader || paymentLoader}
              onClick={handleWaiterPayment}
              className="d-block mt-2 butn success-btn">
              {paymentLoader ? (
                <Spinner className="d-flex mx-auto" size="sm" color="white" />
              ) : (
                'PAY TO WAITER'
              )}
            </button>
          </div>
        </div>
      </div>
    </Col>
  );
}
