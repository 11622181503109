/* eslint-disable no-unused-vars */
import PageTitle from 'components/PageTitle';
import ErrorModal from 'components/Basic Modal/ErrorModal';
import React, { useState, useEffect } from 'react';
import {
  Button,
  CustomInput,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createStations, deleteStations, updateStations } from 'store/actions/stationActions';
import { getAllStations } from 'store/actions/stationActions';

const Stations = () => {
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState();
  let [isAddModal, setIsAddModal] = useState(false);
  let [isEditModal, setIsEditModal] = useState(false);
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [inputName, setInputName] = useState('');
  const [editInputName, setEditInputName] = useState('');
  const [dividerDropDown, setDividerDropDown] = useState(false);
  const [stationShows, setStationShow] = useState(false);
  const [loading, setLoading] = useState(false);
  //   const [stationsData, setStationsData] = useState([]);
  const [displayMode, setDisplayMode] = useState('');
  const [print, setPrint] = useState(false);
  const [printType, setPrintType] = useState('');
  const [autoServe, SetAutoServe] = useState(false);
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [deletItemId, setDeletItemId] = useState("")
  const initial_list = useSelector((state) => state.stationReducers.initial_list);
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsAddModal(false);
    setTitle('');
    SetAutoServe(false);
    setDisplayMode(false);
    setInputName('');
    setStationShow(false);
    setPrint(false);
    setPrintType('')

  };

  const handleEditClose = () => {
    setIsEditModal(false);
    setTitle('');
    SetAutoServe(false);
    setDisplayMode(false);
    setInputName('');
    setStationShow(false);
    setPrint(false);
    setPrintType('')
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!displayMode) {
      setShowErrorModal(true);
      setShowErrorMsg("please Select Dispaly Mode");
      return;
    }
    setLoading(true);
    let obj = {
      title: inputName,
      enableStation: stationShows,
      displayMode: displayMode,
      autoServe: autoServe,
      printType: printType
      //   id: new Date().getTime().toString(),
    };
    await dispatch(createStations(obj), setInputName(''));
    handleClose();
    setLoading(false);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    if (!displayMode) {
      setShowErrorModal(true);
      setShowErrorMsg("please Select Dispaly Mode");
      return;
    }
    setLoading(true);
    let obj = {
      id: id,
      title: editInputName,
      enableStation: stationShows,
      displayMode: displayMode,
      autoServe: autoServe,
      printType: print ? printType : ""
      //   id: new Date().getTime().toString(),
    };
    dispatch(updateStations(obj), setEditInputName('')).then((resp) => {
      handleEditClose()
      if (resp) {
        setShowErrorMsg('Station Updated');
        setShowErrorModal(true);
      }
    });
    setLoading(false);
    setIsEditModal((prevState) => !prevState);
  };
  useEffect(() => {
    dispatch(getAllStations());
    // Safe to add dispatch to the dependencies array
  }, []);
  const toggle = () => {
    setDividerDropDown((prevState) => !prevState);
  };
  const handleDeleteClose = () => {
    setIsDeleteModal(false)
    setDeletItemId('')
  }

  return (
    <>
      <div className="card p-2 border border-light rounded w-50">
        <PageTitle title="Stations" icon="/img/sidebar/tag.png" />
      </div>
      <div className="d-flex justify-content-end ">
        <button
          onClick={() => {
            setIsAddModal((prevState) => !prevState);
            setTitle('Add Station');
          }}
          className="btn btn-primary ">
          <i className="fa fa-plus-circle"></i>
        </button>
      </div>

      <Modal className="rounded-0 bg-white" isOpen={isAddModal} toggle={handleClose}>
        <ModalHeader className=" border-0" toggle={handleClose}></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">{title}</h3>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup className="mb-0">
              <div className=" d-flex justify-content-between mt-3">
                <div className="mt-2">
                  <Label> Show On Live</Label>
                </div>
                <div className="mt-2">
                  <CustomInput
                    className=" mx-2"
                    checked={stationShows}
                    type="switch"
                    id={'stationVisible'}
                    onClick={() => {
                      setStationShow(!stationShows);
                    }}
                  />
                </div>
              </div>
              <>
                {/* <Label>
                    Item Name <span className="text-danger font-weight-bold">*</span>
                  </Label> */}
                <Input
                  className="border- league-form__input rounded-0 mt-2"
                  required
                  value={inputName}
                  placeholder='Station Name'
                  onChange={(e) => setInputName(e.target.value)}
                />
                <div className="d-flex justify-content-between mt-3 ">
                  {/* <Input
                    className="border- league-form__input rounded-0 mr-2"
                    required
                    value={displayMode == "Divider View" ? "Group View" : displayMode}
                    onChange={(e) => setEditInputName(e.target.value)}
                  /> */}
                  <Dropdown isOpen={dividerDropDown} toggle={toggle} direction={'down'}>
                    <DropdownToggle caret>{displayMode == "" ? "Select View" : displayMode == "Divider View" ? "Group View" : displayMode}</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        onClick={(e) => setDisplayMode(e.target.value)}
                        value={'Divider View'}>
                        Group View
                      </DropdownItem>
                      <DropdownItem
                        onClick={(e) => setDisplayMode(e.target.value)}
                        value="Signle View">
                        Single View
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                {/* <div className="d-flex mt-3">
                <label>Show</label>
                <CustomInput
                  className="mt-1 mx-3"
                  checked={stationShows}
                  type="switch"
                  id={'stationVisible'}
                  onClick={() => {
                    setStationShow(!stationShows);
                  }}
                />
              </div>  */}
                <div className="d-flex justify-content-between mt-3">
                  <div className="d-flex mt-2">
                    <label> Auto Serve Station</label>
                    <CustomInput
                      className=" mx-2"
                      checked={autoServe}
                      type="switch"
                      id={'autoSave'}
                      onClick={() => {
                        SetAutoServe(!autoServe);
                      }}
                    />
                  </div>
                  <div className="d-flex mt-2">
                    <label> Print </label>
                    <CustomInput
                      className=" mx-2"
                      checked={print}
                      type="switch"
                      id={'printVisible'}
                      onClick={() => {
                        setPrint(!print);
                      }}
                    />
                  </div>
                </div>
                {print && (
                  <div className="d-flex justify-content-around mt-3">
                    <div>
                      <div
                        className={
                          printType === 'mobile'
                            ? 'btn-print-select text-center'
                            : 'btn-print text-center'
                        }
                        onClick={() => setPrintType('mobile')}>
                        <div className="p-2">
                          <img src="/img/android.png" alt="mobile" height={40} width={50} />
                        </div>
                      </div>
                      <div className="mt-2 d-flex justify-content-center">
                        <span> Android Print</span>
                      </div>
                    </div>
                    <div className="">
                      <div
                        className={printType === 'web' ? 'btn-print-select' : 'btn-print'}
                        onClick={() => setPrintType('web')}>
                        <div className="p-2 d-flex justify-content-center">
                          <img src="/img/windows.png" alt="mobile" height={40} width={30} />
                        </div>
                      </div>
                      <div className="mt-2 d-flex justify-content-center">
                        <span> Windows Print</span>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </FormGroup>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit">
              {loading ? <Spinner size="sm" /> : <span>Add +</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <div className="mt-3 card">
        <Table striped responsive>
          <thead>
            <tr>
              <th>#</th>
              <th className="text-center">Show On Live</th>
              <th className="">Station</th>
              <th className="">Display Mode</th>
              <th className="text-center">Auto Serve</th>
              <th className="text-center">Print</th>
              <th className="text-center">Actions</th>
            </tr>
          </thead>
          <tbody>
            {initial_list.map((data, ind) => {
              return (
                <tr key={ind}>
                  <th scope="row">{ind + 1}</th>
                  <td className="text-center">
                    <CustomInput checked={data?.enableStation} type="switch" id={ind} />
                  </td>
                  <td>{data.title} </td>

                  <td>{data?.displayMode == "Divider View" ? "Group View" : data?.displayMode}</td>
                  <td className="text-center">
                    <CustomInput checked={data?.autoServe} type="switch" id={ind} />
                  </td>
                  <td className="text-center">
                    <div className="d-flex justify-content-around">
                      <div
                        className={data?.printType === 'mobile' ? 'tbl-select-print' : 'tbl-print'}
                        onClick={() => setPrintType('mobile')}>
                        <div className="p-2">
                          <img src="/img/mobile.png" alt="mobile" height={20} width={20} />
                        </div>
                      </div>
                      <div
                        className={data?.printType === 'web' ? 'tbl-select-print' : 'tbl-print'}
                        onClick={() => setPrintType('web')}>
                        <div className="p-2 d-flex justify-content-center">
                          <img src="/img/pc.png" alt="mobile" height={20} width={20} />
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="text-center">
                    <button
                      className="btn"
                      onClick={() => {
                        setIsEditModal((prevState) => !prevState);
                        setTitle('Edit Station');
                        setEditInputName(data.title);
                        setDisplayMode(data?.displayMode);
                        setStationShow(data?.enableStation);
                        setPrintType(data?.printType);
                        SetAutoServe(data?.autoServe);
                        if (data?.printType) {
                          setPrint(true)
                        }
                        setId(data._id);
                      }}>
                      <i className="fa fa-pen fa-sm"></i>
                    </button>
                    <button className="btn">
                      <i
                        className="fa fa-trash fa-sm"
                        onClick={() => {
                          setIsDeleteModal(true)
                          setDeletItemId(data._id)
                        }}></i>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      <Modal className="rounded-0 bg-white" isOpen={isEditModal} toggle={handleEditClose}>
        <ModalHeader className=" border-0" toggle={handleEditClose}></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">{title}</h3>
          <Form role="form" onSubmit={handleEditSubmit}>
            <div className=" d-flex justify-content-between mt-3">
              <div className="mt-2">
                <label> Show On Live</label>
              </div>
              <div className="mt-2">
                <CustomInput
                  className=" mx-2"
                  checked={stationShows}
                  type="switch"
                  id={'stationVisible'}
                  onClick={() => {
                    setStationShow(!stationShows);
                  }}
                />
              </div>
            </div>
            <div className="">
              <div>
                <FormGroup className="mb-0">
                  <Label>
                    Item Name <span className="text-danger font-weight-bold">*</span>
                  </Label>
                  <Input
                    className="border- league-form__input rounded-0"
                    required
                    value={editInputName}
                    onChange={(e) => setEditInputName(e.target.value)}
                  />
                  <div className="d-flex justify-content-between mt-3 ">
                    {/* <Input
                      className="border- league-form__input rounded-0 mr-2"
                      required
                      value={displayMode == "Divider View" ? "Group View" : displayMode}
                      onChange={(e) => setEditInputName(e.target.value)}
                    /> */}
                    <Dropdown isOpen={dividerDropDown} toggle={toggle} direction={'down'}>
                      <DropdownToggle caret>{displayMode == "" ? "Select View" : displayMode == "Divider View" ? "Group View" : displayMode}</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={(e) => setDisplayMode(e.target.value)}
                          value={'Divider View'}>
                          Group View
                        </DropdownItem>
                        <DropdownItem
                          onClick={(e) => setDisplayMode(e.target.value)}
                          value="Single View">
                          Single View
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex mt-2">
                      <label> Auto Serve Station</label>
                      <CustomInput
                        className=" mx-2"
                        checked={autoServe}
                        type="switch"
                        id={'autoSave'}
                        onClick={() => {
                          SetAutoServe(!autoServe);
                        }}
                      />
                    </div>
                    <div className="d-flex mt-2">
                      <label> Print </label>
                      <CustomInput
                        className=" mx-2"
                        checked={print}
                        type="switch"
                        id={'printVisible'}
                        onClick={() => {
                          setPrint(!print);
                        }}
                      />
                    </div>
                  </div>
                  {print && (
                    <div className="d-flex justify-content-around mt-3">
                      <div>
                        <div
                          className={
                            printType === 'mobile'
                              ? 'btn-print-select text-center'
                              : 'btn-print text-center'
                          }
                          onClick={() => setPrintType('mobile')}>
                          <div className="p-2">
                            <img src="/img/android.png" alt="mobile" height={40} width={50} />
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-center">
                          <span> Android Print</span>
                        </div>
                      </div>
                      <div className="">
                        <div
                          className={printType === 'web' ? 'btn-print-select' : 'btn-print'}
                          onClick={() => setPrintType('web')}>
                          <div className="p-2 d-flex justify-content-center">
                            <img src="/img/windows.png" alt="mobile" height={40} width={30} />
                          </div>
                        </div>
                        <div className="mt-2 d-flex justify-content-center">
                          <span> Windows Print</span>
                        </div>
                      </div>
                    </div>
                  )}
                </FormGroup>
              </div>
            </div>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              type="submit">
              {loading ? <Spinner size="sm" /> : <span>Update</span>}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
      <ErrorModal
        display={showErrorModal}
        size="lg"
        center="true"
        handleClose={() => {
          setShowErrorModal(false);
        }}
        footer={true}
        content={
          <>
            <h1 className="text-center">{showErrorMsg}</h1>
          </>
        }
      />

      <Modal className="rounded-0 bg-white" isOpen={isDeleteModal} toggle={handleDeleteClose}>
        <ModalHeader className=" border-0" toggle={handleDeleteClose}></ModalHeader>
        <ModalBody className="pt-0 px-5 pb-5">
          <h3 className="font-weight-bold text-uppercase text-center">ARE YOU WANT TO DELETE STATION</h3>
          <div className='d-flex'>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="danger"
            >
              {loading ? <Spinner size="sm" /> : <span onClick={() => {
                try {
                  setLoading(true)
                  dispatch(deleteStations(deletItemId)).then((res) => {
                    setLoading(false)
                    if (res == true) {
                      handleDeleteClose()
                      setShowErrorMsg("Staion Deleted Successfully");
                      setShowErrorModal(true);
                    } else {
                      setShowErrorModal(true);
                      setShowErrorMsg(res);
                      handleDeleteClose()
                    }
                  })
                } catch (error) {
                  console.log(error)
                  setLoading(false)
                }

              }}>DELETE</span>}
            </Button>
            <Button
              block
              className="rounded-0 w-100 font-weight-bold text-uppercase py-3 mt-3"
              color="primary"
              onClick={handleDeleteClose}
            >
              Close
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Stations;
