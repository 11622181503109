import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSlide({children, title, handleClose, open, save, saveTitle}) {
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                onBackdropClick="false"
                aria-describedby="alert-dialog-slide-description"
                fullWidth
            >
                {handleClose && <DialogActions>
                    <Button onClick={handleClose} variant='outlined' color='error'>
                        <CloseIcon/>
                    </Button>
                </DialogActions>}
                <DialogTitle className='font-semibold'
                             style={{
                                 width: '100%', display: 'flex', justifyContent: 'center', textAlign: 'center'
                             }}>
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {children}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {save && <Button onClick={() => {
                        save()
                        handleClose()
                    }} variant='outlined' color='primary'>
                        {saveTitle ? saveTitle: "save"}
                    </Button>}
                </DialogActions>
            </Dialog>
        </div>
    );
}