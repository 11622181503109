import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useCallback, useEffect, useState } from 'react';
import { absoluteUrl } from 'utils/backendUrl';
import axios from 'axios';
import dayjs from 'dayjs';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { successToast } from 'components/Toasts/toast';
import { errorToast } from 'components/Toasts/toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { compareDesc, format, parse } from 'date-fns';

const TimerModal = ({ modal, toggle, blId, group }) => {

  const [allDaysstartTime, setallDaysstartTime] = useState();
  const [allDaysendTime, setallDaysendTime] = useState();
  const [visible, setVisible] = useState();
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [groupId, setGroupId] = useState();
  const [isGroupActive, setIsGroupActive] = useState();

  const queryClient = useQueryClient();

  console.log("group ", groupId)

  const handleVisibility = () => {
    setVisible(!visible);
  };
  const handleActive = () => {
    setIsGroupActive(!isGroupActive);
  };

  useEffect(() => {
    if (group != undefined) {
      setallDaysstartTime(group.startTime);
      setallDaysendTime(group.endTime);
      setVisible(group.visible);
      setIsGroupActive(group.active);
      setGroupId(group._id);
      if (compareDesc(new Date(group.startTime), new Date(group.endTime)) == -1)
        setSaveDisabled(true);
      else setSaveDisabled(false);
    }
  }, [group, blId]);


  const updateGroupVisibility = useMutation({
    mutationFn: async () => {
      const updatedGroups = await axios.put(
        absoluteUrl(`/group-logo/update_timeslot/${blId}/${groupId}`),
        {
          startTime: allDaysstartTime,
          endTime: allDaysendTime,
          visible: visible,
          active: isGroupActive
        }
      );

      if (!updatedGroups) {
        errorToast(`❌ Error updating the group visibility`);
        throw new Error('Error updating the group visibility');
      }

      successToast(`🎉 Group visibility has changed successfully`);

      return updatedGroups.data.msg;
    },
    onSuccess: () => {
      // this refetches again the groups from server
      // just as a backup plan

      queryClient.invalidateQueries(['allgroups', blId]);
    }
  });

  const handleUpdateTimeSlot = async (e) => {
    e.preventDefault();
    try {
      updateGroupVisibility.mutate();
      toggle();
    } catch (error) {
      console.log('ERROR_UPDATING_GROUPS_VISIBILITY', error);
    }
  };

  const handleStartTimeChange = useCallback(
    (newTime) => {
      //const formattedDate = format(newTime, "HH:mm")
      //console.log("formated date ", parse(formattedDate, "HH:mm", new Date()))

      if (
        compareDesc(new Date(newTime), new Date(parse(allDaysendTime, 'HH:mm', new Date()))) == -1
      ) {
        setSaveDisabled(true);
        errorToast('Start time should not be later than end time!');
      } else {
        setallDaysstartTime(format(newTime, 'HH:mm'));
        setSaveDisabled(false);
      }
    },
    [allDaysstartTime, allDaysendTime]
  );

  const handleEndTimeChange = useCallback(
    (newTime) => {
      if (
        compareDesc(new Date(parse(allDaysstartTime, 'HH:mm', new Date())), new Date(newTime)) == -1
      ) {
        setSaveDisabled(true);
        errorToast('Start time should not be later than end time!');
      } else {
        setallDaysendTime(format(newTime, 'HH:mm'));
        setSaveDisabled(false);
      }
    },
    [allDaysstartTime, allDaysendTime]
  );

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop={true} size={'md'}>
      <ModalHeader toggle={toggle}>Visibility</ModalHeader>
      <ModalBody className="d-flex">
        <div
          className="d-flex flex-column justify-content-between"
          style={{
            width: '100%'
          }}
        >
          {!!group && (
            <div
              className="d-flex flex-col"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '1rem',
                width: '100%'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  padding: '0.5rem 1rem'
                }}
              >
                <div
                  className="text-xl px-4"
                  style={{
                    fontStyle: 'oblique',
                    backgroundColor: '#3e3e3e3e',
                    borderRadius: '8px'
                  }}
                >
                  {group.group}
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  padding: '0.5rem 1rem'
                }}
              >
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={(e) => {
                        e.preventDefault();
                        handleVisibility();
                      }}
                      inputProps={{ 'aria-label': 'controlled' }}
                      // size="small"
                      checked={visible}
                    />
                  }
                  label="Visibility"
                />
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      onChange={() => {
                        handleActive();
                      }}
                      checked={isGroupActive}
                    />
                  }
                  label="Time slot"
                />
              </div>
              {isGroupActive && (
                <div
                  className="d-flex"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    gap: '8px',
                    padding: '0.5rem 1rem'
                  }}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        ampm={false}
                        value={dayjs(parse(allDaysstartTime, 'HH:mm', new Date()))}
                        label={<span>Start Time</span>}
                        onChange={(e) => handleStartTimeChange(e.$d)}
                        disabled={!isGroupActive}
                      />
                    </DemoContainer>
                  </LocalizationProvider>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['TimePicker']}>
                      <TimePicker
                        ampm={false}
                        defaultValue={'23:59'}
                        value={dayjs(parse(allDaysendTime, 'HH:mm', new Date()))}
                        label={<span>End Time</span>}
                        onChange={(e) => handleEndTimeChange(e.$d)}
                        disabled={!isGroupActive}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              )}
            </div>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={() => toggle()}>
          Cancel
        </Button>
        <Button color="primary" onClick={handleUpdateTimeSlot} disabled={saveDisabled}>
          Save
        </Button>{' '}
      </ModalFooter>
    </Modal>
  );
};

export default TimerModal;
