import Categories from 'views/store/articles/Categories';
import Index from 'views/store/articles/Index';
// import PostalCode from 'views/store/articles/PostalCode';
// import LimitedOrders from 'views/store/articles/LimitedOrders';
// import Marketing from 'views/store/articles/Marketing';
import Ingredients from 'views/store/articles/Ingredients';
import Menus from 'views/store/articles/Menus';
import FloorPlans from 'views/store/in-house/FloorPlans';
// import GuestRegistration from 'views/store/in-house/GuestRegistration';
// import Orders from 'views/store/in-house/Orders';
import QrCodes from 'views/store/in-house/QrCodes';
// import Reporting from 'views/store/in-house/Reporting';
import Settings from 'views/store/in-house/Settings';
import ArticleSettings from 'views/store/Settings';
import Promotions from 'views/store/Promotions';
// import Externtools from 'views/store/externaltools/Externtools';
// import Link1 from 'views/store/externaltools/Link1';
// import Link2 from 'views/store/externaltools/Link1';
// import Order from './views/store/Orders';
import GuestBook from './views/store/GuestBook';
import Stations from 'views/store/articles/Stations';
import Users from 'views/store/articles/Users';
import ParkingStations from 'views/store/articles/ParkingStations';
import CreateSessions from 'views/store/in-house/CreateSession';

var routes = [
  {
    path: '/settings',
    name: 'Store Settings',
    icon: '/img/sidebar/store.png',
    icon_active: '/img/white/store.png',
    select_icon: '/img/white/store.png',
    component: ArticleSettings,
    layout: '/store'
  },
  {
    path: '/articles/articles',
    name: 'Articles',
    icon: '/img/sidebar/spoon.png',
    icon_active: '/img/white/spoon.png',
    select_icon: '/img/white/spoon.png',
    layout: '/store',
    children: [
      {
        name: 'Articles',
        icon: '/img/sidebar/spoon.png',
        icon_active: '/img/sidebar/spoon-blue.png',
        component: Index,
        layout: '/store',
        type: 'header'
      },
      {
        path: '/articles/articles',
        name: 'Articles',
        icon: '/img/sidebar/spoon.png',
        icon_active: '/img/sidebar/spoon-blue.png',
        component: Index,
        layout: '/store'
      },
      {
        path: '/articles/ingredients',
        name: 'Ingredients',
        icon: '/img/sidebar/bowl.png',
        icon_active: '/img/sidebar/bowl-blue.png',
        component: Ingredients,
        layout: '/store'
      },
      {
        path: '/articles/categories',
        name: 'Categories',
        icon: '/img/sidebar/tag.png',
        icon_active: '/img/sidebar/tag-blue.png',
        component: Categories,
        layout: '/store'
      },
      {
        path: '/articles/menus',
        name: 'Menus',
        icon: '/img/sidebar/burger.png',
        icon_active: '/img/sidebar/burger-blue.png',
        component: Menus,
        layout: '/store'
      }
      // {
      //   path: '/articles/subgroups',
      //   name: 'Supergroups',
      //   icon: '/img/sidebar/object.png',
      //   icon_active: '/img/sidebar/object-blue.png',
      //   component: Subgroups,
      //   layout: '/store'
      // },
      // {
      //   path: '/articles/subgroups-articles',
      //   name: 'Supergroup Articles',
      //   icon: '/img/sidebar/object.png',
      //   icon_active: '/img/sidebar/object-blue.png',
      //   component: SubgroupsArticles,
      //   layout: '/store'
      // }
    ]
  },
  {
    path: '/kitchen/users',
    name: 'Kitchen',
    icon: '/img/sidebar/spoon.png',
    icon_active: '/img/white/spoon.png',
    select_icon: '/img/white/spoon.png',
    layout: '/store',
    children: [
      {
        path: '/kitchen/users',
        name: 'Users',
        icon: '/img/sidebar/burger.png',
        icon_active: '/img/sidebar/burger-blue.png',
        component: Users,
        layout: '/store'
      },
      {
        path: '/kitchen/stations',
        name: 'Stations',
        icon: '/img/sidebar/burger.png',
        icon_active: '/img/sidebar/burger-blue.png',
        component: Stations,
        layout: '/store'
      },
      {
        path: '/kitchen/parking-stations',
        name: 'Parking stations',
        icon: '/img/sidebar/burger.png',
        icon_active: '/img/sidebar/burger-blue.png',
        component: ParkingStations,
        layout: '/store'
      }
    ]
  },
  {
    path: '/session/settings',
    name: 'Sessions',
    icon: '/img/sidebar/map.png',
    icon_active: '/img/sidebar/map-blue.png',
    component: FloorPlans,
    layout: '/store'
  },
  {
    path: '/create-session',
    name: 'Create Session',
    icon: '/img/sidebar/report.png',
    icon_active: '/img/white/report.png',
    select_icon: '/img/white/report.png',
    component: CreateSessions,
    layout: '/store'
  },
  {
    path: '/in-store/settings',
    name: 'InStore',
    icon: '/img/sidebar/home.png',
    icon_active: '/img/white/home.png',
    select_icon: '/img/white/home.png',
    layout: '/store',
    children: [
      {
        name: 'InStore',
        icon: '/img/sidebar/home.png',
        icon_active: '/img/sidebar/home-blue.png',
        layout: '/store',
        type: 'header'
      },
      {
        path: '/in-store/settings',
        name: 'Settings',
        icon: '/img/sidebar/setting.png',
        icon_active: '/img/sidebar/setting-blue.png',
        component: Settings,
        layout: '/store'
      },
      // {
      //   path: '/in-store/reporting',
      //   name: 'Reporting',
      //   icon: '/img/sidebar/report.png',
      //   icon_active: '/img/sidebar/report-blue.png',
      //   component: Reporting,
      //   layout: '/store'
      // },
      // {
      //   path: '/in-store/orders',
      //   name: 'Orders',
      //   icon: '/img/sidebar/burger.png',
      //   icon_active: '/img/sidebar/burger-blue.png',
      //   component: Orders,
      //   layout: '/store'
      // },

      {
        path: '/in-store/qr-codes',
        name: 'QR Codes',
        icon: '/img/sidebar/qr-code.png',
        icon_active: '/img/sidebar/qr-code-blue.png',
        component: QrCodes,
        layout: '/store'
      },
      // {
      //   path: '/in-store/guest-registration',
      //   name: 'Guest Registration',
      //   icon: '/img/sidebar/pen.png',
      //   icon_active: '/img/sidebar/pen-blue.png',
      //   component: GuestRegistration,
      //   layout: '/store'
      // }
    ]
  },
  {
    path: '/takeaway-delivery/settings',
    name: 'Takeaway | Delivery',
    icon: '/img/sidebar/jar.png',
    icon_active: '/img/white/jar.png',
    select_icon: '/img/white/jar.png',
    layout: '/store',
    children: [
      {
        name: 'Takeaway | Delivery',
        icon: '/img/sidebar/jar.png',
        icon_active: '/img/sidebar/jar-blue.png',
        layout: '/store',
        type: 'header'
      },
      {
        path: '/takeaway-delivery/settings',
        name: 'Settings',
        icon: '/img/sidebar/setting.png',
        icon_active: '/img/sidebar/setting-blue.png',
        component: Settings,
        layout: '/store'
      },

      // {
      //   path: '/takeaway-delivery/reporting',
      //   name: 'Reporting',
      //   icon: '/img/sidebar/report.png',
      //   icon_active: '/img/sidebar/report-blue.png',
      //   component: Reporting,
      //   layout: '/store'
      // },
      // {
      //   path: '/takeaway-delivery/postalCode',
      //   name: 'PostalCode',
      //   icon: '/img/sidebar/setting.png',
      //   icon_active: '/img/sidebar/setting-blue.png',
      //   component: PostalCode,
      //   layout: '/store'
      // },
      // {
      //   path: '/takeaway-delivery/orders',
      //   name: 'Orders',
      //   icon: '/img/sidebar/burger.png',
      //   icon_active: '/img/sidebar/burger-blue.png',
      //   component: Orders,
      //   layout: '/store'
      // },
      // {
      //   path: '/takeaway-delivery/marketing',
      //   name: 'Marketing',
      //   icon: '/img/sidebar/at_b.png',
      //   icon_active: '/img/sidebar/at-blue.png',
      //   component: Marketing,
      //   layout: '/store'
      // },
      {
        path: '/takeaway-delivery/qr-codes',
        name: 'QR Codes',
        icon: '/img/sidebar/qr-code.png',
        icon_active: '/img/sidebar/qr-code-blue.png',
        component: QrCodes,
        layout: '/store'
      },
      //   {
      //     path: "/takeaway-delivery/floor-plans",
      //     name: "Floor Plans",
      //     icon: "/img/sidebar/map.png",
      //     icon_active: "/img/sidebar/map-blue.png",
      //     component: FloorPlans,
      //     layout: "/store",
      //   },
      // {
      //   path: '/takeaway-delivery/guest-registration',
      //   name: 'Guest Registration',
      //   icon: '/img/sidebar/pen.png',
      //   icon_active: '/img/sidebar/pen-blue.png',
      //   component: GuestRegistration,
      //   layout: '/store'
      // },
      // {
      //   path: '/takeaway-delivery/limitedOrders',
      //   name: 'Limited Orders',
      //   icon: '/img/sidebar/speed.png',
      //   icon_active: '/img/sidebar/speed-blue.png',
      //   component: LimitedOrders,
      //   layout: '/store'
      // }
    ]
  },
  {
    path: '/promotions',
    name: 'Promotions',
    icon: '/img/sidebar/mike.png',
    icon_active: '/img/white/mike.png',
    select_icon: '/img/white/mike.png',
    component: Promotions,
    layout: '/store'
  },
  // {
  //   path: '/order',
  //   name: 'Orders',
  //   icon: '/img/sidebar/order.png',
  //   icon_active: '/img/white/order.png',
  //   select_icon: '/img/white/order.png',
  //   component: Order,
  //   layout: '/store'
  // },

  {
    path: '/guestbook',
    name: 'Guest Book',
    icon: '/img/sidebar/spoon.png',
    icon_active: '/img/white/spoon.png',
    select_icon: '/img/white/spoon.png',
    component: GuestBook,
    layout: '/store'
  },
  // {
  //   path: '/reporting',
  //   name: 'Reporting',
  //   icon: '/img/sidebar/report.png',
  //   icon_active: '/img/white/report.png',
  //   select_icon: '/img/white/report.png',
  //   component: Reporting,
  //   layout: '/store'
  // },
  // {
  //   path: '/external-tool/tools',
  //   name: 'External Tools',
  //   icon: '/img/sidebar/setting.png',
  //   icon_active: '/img/white/setting.png',
  //   select_icon: '/img/white/setting.png',
  //   layout: '/store',
  //   children: [
  //     {
  //       path: '/external-tool/tools',
  //       name: 'External Tools',
  //       icon: '/img/sidebar/setting.png',
  //       icon_active: '/img/sidebar/setting-blue.png',
  //       component: Externtools,
  //       layout: '/store'
  //     },
  //     {
  //       path: '/externaltool/link1',
  //       name: 'Link 1',
  //       icon: '/img/sidebar/link.png',
  //       icon_active: '/img/sidebar/link-blue.png',
  //       component: Link1,
  //       layout: '/store'
  //     },

  //     {
  //       path: '/externaltool/link2',
  //       name: 'Link 2',
  //       icon: '/img/sidebar/link.png',
  //       icon_active: '/img/sidebar/link-blue.png',
  //       component: Link2,
  //       layout: '/store'
  //     }
  //   ]
  // }
];
export default routes;
