import toast from "react-hot-toast";

export function successToast(message) {
    toast.success(message, {
        position: 'bottom-right',
        style: {
            fontSize: '14px'
        }
    })
}

export function errorToast(message) {
    toast.error(message, {
        position: 'bottom-right',
        style: {
            fontSize: '14px'
        }
    })
}

export function customToast(message, icon) {
    toast(message, {
        position: 'bottom-right',
        icon: icon,
        style: {
            border: '2px solid #713200',
            padding: '12px',
            color: '#713200'
        },
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE'
        }
    })
}