
import { RepositoryFactory } from 'repository/RepositoryFactory';
import { CREATE_SESSION_FAIL } from 'store/Types/SessionType';
import { CREATE_SESSION_SUCCESS } from 'store/Types/SessionType';
import { CREATE_SESSION_REQUEST } from 'store/Types/SessionType';
let sessionRepo = RepositoryFactory.get('session');


export const createSession = (val) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SESSION_REQUEST });
    let res = await sessionRepo.createSessionRepo(val);
    if(res){
      dispatch({
        type: CREATE_SESSION_SUCCESS,
        payload: res.data
      });
      alert("Session Created")
    }
    
  } catch (error) {
    console.log(error?.response)
    dispatch({
      type: CREATE_SESSION_FAIL,
      payload: error
    });
    alert(error?.response.data?.message)

  }
};
