export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';

export const SET_MENU_POSITION_REQUEST = 'SET_MENU_POSITION_REQUEST';
export const SET_MENU_POSITION_SUCCESS = 'SET_MENU_POSITION_SUCCESS';
export const SET_MENU_POSITION_FAILURE = 'SET_MENU_POSITION_FAILURE';

export const UPDATE_MENU_POSITION_REQUEST = 'UPDATE_MENU_POSITION_REQUEST';
export const UPDATE_MENU_POSITION_SUCCESS = 'UPDATE_MENU_POSITION_SUCCESS';
export const UPDATE_MENU_POSITION_FAILURE = 'UPDATE_MENU_POSITION_FAILURE';

export const FETCH_TAKEAWAY_MENU_REQUEST = 'FETCH_TAKEAWAY_MENU_REQUEST';
export const FETCH_TAKEAWAY_MENU_SUCCESS = 'FETCH_TAKEAWAY_MENU_SUCCESS';
export const FETCH_TAKEAWAY_MENU_FAILURE = 'FETCH_TAKEAWAY_MENU_FAILURE';

export const FETCH_KIOSK_MENU_REQUEST = 'FETCH_KIOSK_MENU_REQUEST';
export const FETCH_KIOSK_MENU_SUCCESS = 'FETCH_KIOSK_MENU_SUCCESS';
export const FETCH_KIOSK_MENU_FAILURE = 'FETCH_KIOSK_MENU_FAILURE';

export const SET_PRIORITY_REQUEST = 'SET_PRIORITY_REQUEST';
export const SET_PRIORITY_SUCCESS = 'SET_PRIORITY_SUCCESS';
export const SET_PRIORITY_FAILURE = 'SET_PRIORITY_FAILURE';

export const DELETE_MENU_REQUEST = 'DELETE_MENU_REQUEST';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAILURE = 'DELETE_MENU_FAILURE';

export const CREATE_MENU_REQUEST = 'CREATE_MENU_REQUEST';
export const CREATE_MENU_SUCCESS = 'CREATE_MENU_SUCCESS';
export const CREATE_MENU_FAILURE = 'CREATE_MENU_FAILURE';

export const FETCH_ALLE_ARTIKELENA_REQUEST = 'FETCH_ALLE_ARTIKELENA_REQUEST';
export const FETCH_ALLE_ARTIKELENA_SUCCESS = 'FETCH_ALLE_ARTIKELENA_SUCCESS';
export const FETCH_ALLE_ARTIKELENA_FAILURE = 'FETCH_ALLE_ARTIKELENA_FAILURE';

export const FETCH_SINGLE_MENU_ITEMS_REQUEST = 'FETCH_SINGLE_MENU_ITEMS_REQUEST';
export const FETCH_SINGLE_MENU_ITEMS_SUCCESS = 'FETCH_SINGLE_MENU_ITEMS_SUCCESS';
export const FETCH_SINGLE_MENU_ITEMS_FAILURE = 'FETCH_SINGLE_MENU_ITEMS_FAILURE';

export const ADD_SINGLE_MENU_REQUEST = 'ADD_SINGLE_MENU_REQUEST';
export const ADD_SINGLE_MENU_SUCCESS = 'ADD_SINGLE_MENU_SUCCESS';
export const ADD_SINGLE_MENU_FAILURE = 'ADD_SINGLE_MENU_FAILURE';
