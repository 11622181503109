import Repository from './Repository';
const MENUS = '/menu_v2';
const CREATE_MENUS = '/menus';
const ALLE_ARTIKELENA = '/menus-lightspeed/load';
const TAKE_AWAY_MENUS = '/menu_v2';
const MENU_ITEM = '/menu-item';
const MENU_ITEM_DELETE = '/menus';
const POSITION_DRAG_ITEM = '/menus/order';
const KIOSK_MENU = '/menus/kiosk-menu';
const ADD_PRIORITY = '/menus/add-priority';
export default {
  addPriority(payload) {
    return Repository.put(`${ADD_PRIORITY}/${payload.itemID}`, {
      priority: payload.priority,
      blId: payload.blId
    });
  },
  addMenu(payload) {
    return Repository.post(`${CREATE_MENUS}`, payload);
  },
  getAllMenus(payload) {
    return Repository.get(`${MENUS}/${payload}?BackOffice=true`);
  },
  getAllCacheMenus(payload) {
    return Repository.get(`${MENUS}/${payload}`);
  },
  getAllAlleArtikelen(payload, menu_id) {
    return Repository.get(`${ALLE_ARTIKELENA}/${payload}?menueId=${menu_id}`);
  },
  getAllKioskMenu(payload) {
    return Repository.get(`${KIOSK_MENU}/${payload}`);
  },
  getAllTakeAwayMenus(payload) {
    return Repository.get(`${TAKE_AWAY_MENUS}/${payload}?TakeAway=true`);
  },
  getSingleMenuItems(payload) {
    return Repository.get(`${MENU_ITEM}/${payload}`);
  },
  createMenuItem(payload) {
    return Repository.post(`${MENU_ITEM}`, payload);
  },
  deleteMenu(payload) {
    return Repository.delete(`${MENU_ITEM_DELETE}/${payload}`);
  },
  positionDragItem(payload, blId) {
    console.log(payload, blId);
    return Repository.put(`${POSITION_DRAG_ITEM}/${blId}`, payload);
  },
  updatePosition(blId) {
    return Repository.put(`${MENUS}/updateposition/${blId}`);
  },

};
