import PageTitle from 'components/PageTitle';
import React from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Input, Row, Spinner } from 'reactstrap';
import { createSession } from 'store/actions/sessionAction';

const CreateSessions = () => {
  const { loading } = useSelector((state) => state?.session);
  const [session, setSession] = useState('');
  const [sessionsArr, setArrSessions] = useState([]);
  const [removeSelect, setRemoveSelected] = useState('');
  const dispatch = useDispatch();
  const sessionAdd = () => {
    setArrSessions([...sessionsArr, session]);
    setSession('');
  };
  const removeID = (_session) => {
    console.log(_session);
    if (!removeSelect) {
      setRemoveSelected(_session);
    } else {
      let array = [...sessionsArr];
      array.splice(removeSelect, 1);
      setArrSessions([...array]);
      setRemoveSelected('');
    }
  };
  const createSesssion = () => {
    if(sessionsArr.length > 0){
    dispatch(createSession({
      businessLocationId: '771247277342722',
      space_used: '0',
      table_id: '1',
      cards: sessionsArr
    })).then(()=>{  clearData()})
    }
    else{
      alert("There is no id input.")
    }
  };
  const clearData = () => {
    setRemoveSelected('');
    setArrSessions([]);
    setSession('');
  };
  return (
    <>
      <Row className="p-3">
        <Col lg="6" sm="12" md="6" xs="12">
          <div className="card p-2 border border-light rounded ">
            <PageTitle icon={'/img/sidebar/report.png'} title="Create Session" />
          </div>
        </Col>
        <Col xs="12" sm="12" md="12" lg="12">
          <div className="card p-2 border border-light rounded mt-3">
            <Row className="p-3">
              <Col xs="8" sm="8" md="8" lg="8">
                <Input
                  name="session"
                  value={session}
                  onChange={(e) => setSession(e.target.value)}
                  placeholder="Please input an Id"
                />
              </Col>
              <Col xs="4" sm="4" md="4" lg="4">
                <Button
                  onClick={() => {
                    sessionAdd();
                  }}>
                  ADD ID
                </Button>
              </Col>
              {sessionsArr.length > 0 ? (
                <Col xs="12" sm="12" md="12" lg="12 " className="mt-2">
                  <div className="border rounded p-2">
                    {sessionsArr.map((_session, index) => (
                      <Badge
                        className="m-2 p-3"
                        color={removeSelect === index ? 'danger' : 'info'}
                        pill
                        key={index}
                        onClick={() => {
                          removeID(index);
                        }}>
                        {removeSelect === index ? 'Delete' : _session}
                      </Badge>
                    ))}
                  </div>
                </Col>
              ) : (
                ''
              )}
            </Row>
            <div className="d-flex justify-content-center py-3">
              <Button
                color="info"
                disabled={loading}
                onClick={() => {
                  createSesssion();
                }}>
                {!loading ? 'Create Sessions' : <Spinner />}
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CreateSessions;
